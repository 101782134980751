import React from 'react'
import { useLocation } from 'react-router'

import Header from '../Header/Header'

const Layout: React.FC<any> = ({ children }) => {
  const location = useLocation()

  return (
    <div className="app">
      {location.pathname !== '/login' && <Header />}
      {children}
    </div>
  )
}

export default Layout
