import { FC, useMemo } from 'react'

import { VirtualizedTable, TableType } from 'components/VirtualizedTable'
import { TradePositionHook } from 'components/VirtualizedTable/hooks'

type OpenedPositionsTableProps = {
    analytics: Record<string, Record<ExchangePairAndCoinPair, Analytics>>
    openedPositions: ClientPositionData[]
    exchangePairs: string[]
    coinPairs: string[]
    tradePosition: TradePositionHook
}

export const OpenedPositionsTable: FC<OpenedPositionsTableProps> = ({
  openedPositions,
  analytics,
  tradePosition,
  exchangePairs,
  coinPairs,
}) => {
  const [data, clients] = useMemo(() => {
    const positions: Analytics[] = []
    const clients = new Set<string>()

    for (const { exchangePair, coinPair, side, ...data } of openedPositions) {
      const positionSide = side.toLowerCase()
      const analyticsItem = analytics[positionSide]?.[`${exchangePair}_${coinPair}`]

      positions.push({
        ...analyticsItem,
        ...data,
        openFp: side === 'LONG' ? analyticsItem?.futuresPremium?.open : analyticsItem?.futuresPremium?.close,
        side: side.toLowerCase() as 'long' | 'short',
        broker: exchangePair,
        pair: coinPair,
      })

      data.clients.map(({ name }) => clients.add(name))
    }

    return [positions, [...Array.from(clients)]]
  }, [openedPositions, analytics])

  return (
    <VirtualizedTable
      heading="Opened positions:"
      data={data}
      type={TableType.OPEN}
      exchangePairs={exchangePairs}
      tradePosition={tradePosition}
      coinPairs={coinPairs}
      clients={clients}
    />
  )
}