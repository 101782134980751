import React from 'react'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { Route } from 'react-router'
import { BrowserRouter as Router, Navigate, Routes } from 'react-router-dom'
import './App.css'
import { SnackbarProvider } from 'notistack'

import Layout from './components/Layout'
import Preloader from './components/Preloader/Preloader'
import withAuthRedirect from './hoc/withAuthRedirect'
import { useInit } from './hooks/use-init'
import { redirectToCognitoPage } from './api/auth-api'
import Tables from './pages/Home'
import Notifier from './pages/Notifier/Notifier'

const PrivateTables = withAuthRedirect(Tables)
const PrivateNotifier = withAuthRedirect(Notifier)

function App() {
  const { isLoading, error } = useInit()

  if (isLoading) return <Preloader />

  if (error) {
    return (
      <div style={{ color: 'red', padding: 15 }}>
        <h3>{error}</h3>
        <br />
        <h5
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => redirectToCognitoPage()}
        >
          Click here to try again
        </h5>
      </div>
    )
  }

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<PrivateTables />} />
        <Route path="/notifier" element={<PrivateNotifier />} />
        <Route path="/token-callback*" element={<h2>Authorization in progress</h2>} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Layout>
  )
}

class AppWrap extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error('Error boundary: ', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return (
      <SnackbarProvider maxSnack={5} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
        <Router>
          <App />
        </Router>
      </SnackbarProvider>
    )
  }
}

export default AppWrap
