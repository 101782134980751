export const formatFloat = (value: any, decimals = 5) => {
  const num = Number(value)

  if (isNaN(num)) {
    return value
  }

  return parseFloat(num.toFixed(decimals))
}

export const formatDate = (d: string | number | Date) => new Date(d).toLocaleDateString('en-GB')

export const formatDateRange = (from: number, to: number) => {
  const startDate = formatDate(from)
  const endDate = startDate !== formatDate(to) ? `— ${formatDate(to)}` : ''

  return `${startDate} ${endDate}`
}

export const formatDateTime = (timestamp?: string | number | Date, showSeconds = false) => {
  if (!timestamp) {
    return ''
  }

  const date = new Date(timestamp)
  const options = { hour: '2-digit', minute: '2-digit' } as any

  if (showSeconds) options.second = '2-digit'

  return `${formatDate(date)} ${date.toLocaleTimeString([], options)}`
}

export const buildChartData = (data: string | any[], from: string | number | Date, to: string | number | Date) => {
  const startDate = new Date(from).setHours(0, 0, 0, 0)
  const endDate = new Date(to).setHours(23, 59, 59, 0)

  const chartData = []
  const eMovingAvg = []

  for (let i = 0; i < data.length; i++) {
    const date = new Date(data[i]?.updatedAt)
    const createdAt = formatDateTime(date)

    // @ts-ignore
    if (date >= startDate && date <= endDate) {
      const futuresPoint = {
        x: createdAt,
        y: data[i].futuresPremium,
      }

      chartData.push(futuresPoint)

      if (data[i]?.ema) {
        const emaPoint = {
          x: createdAt,
          y: data[i].ema,
        }

        eMovingAvg.push(emaPoint)
      }
    }
  }

  return { chartData, eMovingAvg }
}

// @ts-ignore
export const daysAgoToDate = (daysAgo: number) => new Date() - 1000 * 60 * 60 * 24 * daysAgo

export const sortPercentages = (a: string, b: string) => parseFloat(a) - parseFloat(b)
