import { instance } from './config';

export const reportApi = {
  getReportCSV: (from: any, to: any) => {
    return instance
      .get('/report/arbitrage', { params: { from, to } })
      .then((res) => res.data)
      .catch((err) => console.log(err));
  },
};
