import { IconButton, Tooltip as MUITooltip, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { FC, ReactNode } from 'react'
import { CellContext } from '@tanstack/react-table'

import { Tooltip } from './Tooltip'
import { ShortLongBtn } from './Filters'
import { TradePosition } from './hooks'

import { TableType } from './'

import {
  FUNDING,
  FUTURES_OPEN,
  FUTURES_CLOSE,
  ONE_D,
  PAIR,
  SEVEN_D,
  THIRTY_D,
  POSITION,
  THREE_D,
  VOLUME
} from 'model/columns-types'
import { formatFloat } from 'utilities/number-helpers'
import { abbreviatedStringFormat } from 'utilities/string-helpers'
import FancyNumber from 'components/FancyNumber/FancyNumber'

const FuturesCell = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;

  &:hover {
    background: rgba(25,118,210,.1);
  }
`

const SymbolCell = styled(Typography)`
  white-space: nowrap;
`

type ClickTooltipProps = {
  showTooltip: (...args: any) => void
  data?: TooltipData
  children?: ReactNode
}

const ClickTooltip: FC<ClickTooltipProps> = ({ showTooltip, children, data }) => {
  if ('ontouchstart' in window) {
    return (
      <div
        aria-describedby="simple-popover"
        onClick={(e) => showTooltip(e, data)}
      >
        {children}
      </div>
    )
  }

  return (
    <MUITooltip placement="right-start" title={<Tooltip {...data}/>} arrow>
      <div>{children}</div>
    </MUITooltip>
  )
}

const pairWithClientsStyle = { cursor: 'pointer', textDecoration: 'underline', fontWeight: 'bold' }

export const getColumns = (
  type: TableType,
  { showTooltip, openGraph, toggleClientTable }: any
): any => ([ // Array<ColumnDef<any, unknown> | false>
  type === TableType.OPEN && {
    header: '',
    accessorKey: 'side',
    size: 50,
    cell: (cell: CellContext<Analytics, any>) => {
      const tradePosition = cell.getValue().toLowerCase() === 'long' ? TradePosition.LONG : TradePosition.SHORT

      return <ShortLongBtn size="small" tradePosition={tradePosition} />
    },
  },
  {
    header: 'Symbol',
    accessorKey: PAIR,
    // size: 80,
    cell: (cell: CellContext<Analytics, any>) => {
      const value = cell.getValue()

      if (type === TableType.CLOSE) {
        return (
          <SymbolCell
            variant="caption"
            style={cell.row.original.clientsCount ? pairWithClientsStyle : {}}
            onClick={() => {
              toggleClientTable?.(value, cell.row.original.broker)
            }}
          >
            {value}
          </SymbolCell>
        )
      }

      return <SymbolCell variant="caption">{value}</SymbolCell>
    },
  },
  {
    header: 'Exchange pair',
    accessorKey: 'broker',
    size: 150,
    cell: (cell: CellContext<Analytics, any>) => <Typography variant="caption">{abbreviatedStringFormat(cell.getValue())}</Typography>,
  },
  {
    header: 'Funding rate',
    accessorKey: FUNDING,
    sortingFn: 'alphanumericFalsyLast',
    size: 75,
    cell: (cell: CellContext<Analytics, any>) => (
      <ClickTooltip data={cell.row.original.fundingTooltip} showTooltip={showTooltip}>
        <FancyNumber value={formatFloat(cell.getValue())} postfix={'%'} />
      </ClickTooltip>
    ),
  },
  {
    header: '1d APR',
    accessorKey: ONE_D,
    size: 75,
    sortingFn: 'alphanumericFalsyLast',
    cell: (cell: CellContext<Analytics, any>) => (
      <ClickTooltip data={cell.row.original.apr1dTooltip} showTooltip={showTooltip}>
        <FancyNumber value={cell.getValue()} postfix={'%'} />
      </ClickTooltip>
    ),
  },
  {
    header: '3d APR',
    accessorKey: THREE_D,
    size: 75,
    sortingFn: 'alphanumericFalsyLast',
    cell: (cell: CellContext<Analytics, any>) => (
      <ClickTooltip data={cell.row.original.apr3dTooltip} showTooltip={showTooltip}>
        <FancyNumber value={cell.getValue()} postfix={'%'} />
      </ClickTooltip>
    ),
  },
  {
    header: '7d APR',
    accessorKey: SEVEN_D,
    size: 75,
    sortingFn: 'alphanumericFalsyLast',
    cell: (cell: CellContext<Analytics, any>) => (
      <ClickTooltip data={cell.row.original.apr7dTooltip} showTooltip={showTooltip}>
        <FancyNumber value={cell.getValue()} postfix={'%'} />
      </ClickTooltip>
    ),
  },
  {
    header: '30d APR',
    size: 75,
    sortingFn: 'alphanumericFalsyLast',
    accessorKey: THIRTY_D,
    cell: (cell: CellContext<Analytics, any>) => (
      <ClickTooltip data={cell.row.original.apr30dTooltip} showTooltip={showTooltip}>
        <FancyNumber value={cell.getValue()} postfix={'%'} />
      </ClickTooltip>
    ),
  },
  type === TableType.OPEN && {
    header: 'FP Close',
    size: 90,
    sortingFn: 'alphanumericFalsyLast',
    accessorKey: FUTURES_OPEN,
    cell: (cell: CellContext<Analytics, any>) => (
      <FuturesCell>
        <IconButton
          onClick={() => openGraph(cell.row.original.pair, cell.row.original.broker)}
          size={'small'}
          style={{ marginLeft: '6px' }}
        >
          <i className="fas fa-chart-line" />
        </IconButton>
        <ClickTooltip data={cell.row.original.fpTimestamp} showTooltip={showTooltip}>
          <FancyNumber value={cell.getValue()} postfix={'%'} />
        </ClickTooltip>
      </FuturesCell>
    ),
  },
  type === TableType.CLOSE && {
    header: 'FP Open',
    size: 90,
    sortingFn: 'alphanumericFalsyLast',
    accessorKey: FUTURES_CLOSE,
    cell: (cell: CellContext<Analytics, any>) => (
      <FuturesCell>
        <IconButton
          onClick={() => openGraph(cell.row.original.pair, cell.row.original.broker)}
          size={'small'}
          style={{ marginLeft: '6px' }}
        >
          <i className="fas fa-chart-line" />
        </IconButton>
        <ClickTooltip data={cell.row.original.fpTimestamp} showTooltip={showTooltip}>
          <FancyNumber value={cell.getValue()} postfix={'%'} />
        </ClickTooltip>
      </FuturesCell>
    ),
  },
  {
    header: '1d volume',
    accessorKey: VOLUME,
    sortingFn: 'alphanumericFalsyLast',
    cell: (cell: CellContext<Analytics, any>) => (
      <ClickTooltip data={cell.row.original.volumeTimestamp} showTooltip={showTooltip}>
        <FancyNumber formatFn={(v: any) => Math.round(v).toLocaleString('en')} value={cell.getValue()} />
      </ClickTooltip>
    ),
    // size: 0,
  },
  type === TableType.OPEN && {
    header: 'Clients',
    accessorKey: 'clientsCount',
    filterFn: 'filterByClient',
    size: 100,
  },
  type === TableType.OPEN && {
    header: 'Positions (USDT)',
    accessorKey: POSITION,
    sortingFn: 'alphanumericFalsyLast',
    cell: (cell: CellContext<Analytics, any>) => formatFloat(cell.getValue(), 2).toLocaleString('en'),
    size: 0,
  },
].filter(v => v))
