import { FC, useState, useCallback } from 'react'
import { flexRender, Row } from '@tanstack/react-table'
import { VirtualItem } from '@tanstack/react-virtual'

import { TableType } from './'

import { formatFloat } from 'utilities/number-helpers'

type TableRowsProps = {
    type: TableType
    rows: Row<Analytics>[]
    virtualRows: VirtualItem[]
}

export const TableRows: FC<TableRowsProps> = ({ rows, virtualRows, type }) => {
  const [openedRow, toggleRow] = useState<number[]>([])

  const toggle = useCallback((rowId: number) => {
    toggleRow((prevRows) => {
      if (prevRows?.includes(rowId)) {
        return prevRows.filter(row => row !== rowId)
      } else {
        return [...prevRows, rowId]
      }
    })
  }, [])

  return (
    <>
      {virtualRows.map((virtualRow: any) => {
        const row: any = rows[virtualRow.index]

        if (type === TableType.OPEN) {
          const visibleCells = row.getVisibleCells()

          return (
            <>
              <tr key={row.id} onClick={() => toggle(row.id)}>
                {visibleCells.map((cell: any) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )
                })}
              </tr>
              {openedRow.includes(row.id) && row.original.clients.map((client: any) => {
                return (
                  <tr className="expanded-row" key={client.name}>
                    {visibleCells.slice(2).map((cell: any) => <td key={cell.id} />)}
                    <td>{client.name}</td>
                    <td>{formatFloat(client.position, 2).toLocaleString('en')}</td>
                  </tr>
                )
              })}
            </>
          )
        }

        return (
          <tr key={row.id} onClick={() => console.log(row.original)}>
            {row.getVisibleCells().map((cell: any) => {
              return (
                <td key={cell.id}>
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                  )}
                </td>
              )
            })}
          </tr>
        )
      })}
    </>
  )
}