import { instance } from './config';

export const notifierApi = {
  getOptions: () => {
    return instance
      .get('/notifier/options')
      .then((res) => res.data)
      .catch((err) => console.log(err));
  },
  saveOption: (option: any) => {
    return instance
      .post('/notifier/option', { ...option })
      .then((res) => res)
      .catch((err) => console.log(err));
  },
};
