import styled from '@emotion/styled'
import { Box as MuiBox } from '@mui/material'

export const Header = styled.div`
  padding: 0.5em;
`

export const TableContainer = styled.div<{ tHeight: number }>`
  height: ${(props: any) => props.tHeight}px;
  overflow: auto;
`

export const Box = styled(MuiBox)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  background: #fff;
  padding: 20px;
`

export const Container = styled.div`
  padding: 0 .5em;

  table {
    border-collapse: collapse;
    border-spacing: 0;
    font-family: arial, sans-serif;
    table-layout: auto;
    width: 100%;
  }

  thead {
    background: #d1e4f6;
    margin: 0;
    position: sticky;
    top: 0;
    padding: 0 .5em;
    z-index: 1;

    tr {
      border: none;
    }
  }

  th {
    text-align: left;
    height: 40px;
    font-size: .8em;
    vertical-align: middle;

    & > div {
      display: flex;
      align-items: center;
      justify-content: start;
    }
  }

  tr {
    height: 40px;
    border-top: 1px solid rgba(25,118,210,.5);

    &:hover {
      background: rgba(25,118,210,.1);
    }

    &.expanded-row {
      background: rgba(25,118,210,.05);
    }

    td {
      padding: 0 4px;

      &:hover {
        cursor: default;
      }

      > div {
        padding: 5px 0;
      }
    }
  }
`