import axios from 'axios'
import * as qs from 'qs'

import config from '../config'
import { generateCodeChallenge, generateCodeVerifier } from '../utilities/encoding'

const cognitoInstance = axios.create({
  baseURL: config.cognito.provider,
})

const fetchToken = (data: any) => {
  return cognitoInstance
    .post('/oauth2/token', qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((res) => res)
    .catch((err) => err.response)
}

export const redirectToCognitoPage = (action = 'login') => {
  const codeVerifier = generateCodeVerifier()
  const codeChallenge = generateCodeChallenge(codeVerifier)

  localStorage.removeItem('token')
  localStorage.removeItem('refresh')
  localStorage.removeItem('lastAuth')
  localStorage.removeItem('expiresIn')
  localStorage.setItem('authenticated', 'false')
  localStorage.setItem('codeVerifier', codeVerifier)
  localStorage.setItem('codeChallenge', codeChallenge)

  // eslint-disable-next-line max-len
  const authURL = `${config.cognito.provider}/${action}?response_type=code&client_id=${config.cognito.clientId}&redirect_uri=${config.cognito.redirectUri}&code_challenge=${codeChallenge}&code_challenge_method=S256`

  window.location.replace(authURL)
}

export async function awsCallTokenEndpoint(grantType: any, accessToken: any, codeVerifier: any) {
  const data = {
    grant_type: grantType,
    client_id: config.cognito.clientId,
    // client_secret: config.cognito.clientSecret,
    code: accessToken,
    scope: 'profile',
    redirect_uri: config.cognito.redirectUri,
    code_verifier: codeVerifier,
  }

  const awsResponse = await fetchToken(data)

  if (awsResponse?.status === 200) {
    return awsResponse?.data
  }

  return awsResponse?.data
}

export async function awsCallTokenRefresh(grantType: any, refreshToken: any) {
  const data = {
    grant_type: grantType,
    client_id: config.cognito.clientId,
    refresh_token: refreshToken,
  }

  const awsResponse = await fetchToken(data)

  if (awsResponse?.status === 200) {
    return awsResponse?.data
  }
}

export const getAwsToken = async (requestCallback: any) => {
  const data = await requestCallback()

  if (!data || data?.error) {
    throw Error(data?.error || 'Token request error')
  } else {
    localStorage.setItem('token', data?.access_token)
    if (data && data.refresh_token) localStorage.setItem('refresh', data.refresh_token)
    localStorage.setItem('authenticated', 'true')
    localStorage.setItem('lastAuth', JSON.stringify(Number(new Date())))
    localStorage.setItem('expiresIn', JSON.stringify(data.expires_in * 1000))
  }
}

export const awsLogout = () => redirectToCognitoPage('logout')

export const isAuthExpired = () => {
  const lastAuth = localStorage.getItem('lastAuth')
  const expiresIn = localStorage.getItem('expiresIn')

  // @ts-ignore
  if (new Date() - lastAuth > expiresIn - 300000) return true

  return false
}

export const getIsAuth = () => {
  try {
    const item = localStorage.getItem('authenticated')

    return item ? JSON.parse(item) : false
  } catch (e) {
    return false
  }
}
