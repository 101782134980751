// import React from "react"
import ReactDOM from "react-dom/client"

import './index.css'
import App from './App'

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <App />
)
