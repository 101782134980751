import CryptoJS from 'crypto-js';

function base64URL(string: any) {
  return string
    .toString(CryptoJS.enc.Base64)
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

function generateRandomString(length: any) {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

export function generateCodeVerifier() {
  return base64URL(generateRandomString(32));
}

export function generateCodeChallenge(codeVerifier: any) {
  return base64URL(CryptoJS.SHA256(codeVerifier));
}
