import axios from 'axios'

import { redirectToCognitoPage } from './auth-api'

export const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}` || `${window.location.origin}/api/`,
})

// handling unauthorized
let confirmCount = 0

instance.interceptors.response.use(undefined, (err) => {
  const error = err.response

  console.log(err)

  if (error?.status === 401 || error?.status === 403) {
    confirmCount += 1
    if (
      confirmCount <= 1
      && window.confirm('Server returned error 401. Do you want to authorize again?')
    ) {
      redirectToCognitoPage()
    }
  }

  return error
})

instance.interceptors.request.use(function (config) {
  const headers = authHeader()

  if (headers) {
    config.headers = { ...config.headers, ...headers }
    config.headers.Accept = 'application/json'
  }

  return config
})

export function authHeader() {
  const token = localStorage.getItem('token')

  if (token) {
    return { Authorization: `Bearer ${token}` }
  }

  return {}
}
