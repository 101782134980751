const config = {
  cognito: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? '',
    redirectUri:
      process.env.REACT_APP_REDIRECT_URI
      ?? 'https://1-test-analytics.internal-fun-chegonibudj.com/token-callback',
    provider: process.env.REACT_APP_PROVIDER ?? 'https://auth.internal-fun-chegonibudj.com',
    scope: process.env.REACT_APP_SCOPE ?? 'funbot/analytics.data',
  },
}

export default config
