import React from 'react'
import {
  Backdrop, Button, Fade, IconButton, Modal, Paper
} from '@mui/material'
import { useRef, useState } from 'react'
import { CSVLink } from 'react-csv'

import { reportApi } from 'api/report-api'
import DateRangeSelect from 'components/DateRangeSelect/DateRangeSelect'
import { daysAgoToDate, formatDateRange } from 'utilities/number-helpers'

// const csvHeaders = [
//   { label: 'Created At', key: 'Timestamp' },
//   { label: 'Exchanges', key: 'Exchanges' },
//   { label: 'Symbol', key: 'Symbol' },
//   { label: 'Best Buy Asset', key: 'Best Buy Asset' },
//   { label: 'Best Buy Arbitrage %', key: 'Best Buy Arbitrage %' },
//   { label: 'Best Sell Asset', key: 'Best Sell Asset' },
//   { label: 'Best Sell Arbitrage %', key: 'Best Sell Arbitrage %' },
// ]

const ExportReportButton = () => {
  const [show, setShow] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [csvData, setCsvData] = useState([])
  const [dateRange, setDateRange] = useState({
    startDate: new Date(daysAgoToDate(3)).getTime(),
    endDate: new Date().getTime(),
  })
  const dateRangeEl = useRef(null)

  const applyRange = (from: { getTime: () => any }, to: string | number | Date) => {
    const startDate = from.getTime()
    const endDate = new Date(to).setHours(23, 59, 59, 999)

    setDateRange({ startDate, endDate })

    if (!dateRangeEl?.current) {
      return
    }

    // @ts-ignore
    dateRangeEl.current.close()
  }

  const donwloadCSV = async () => {
    setIsFetching(true)

    const response = await reportApi.getReportCSV(dateRange.startDate, dateRange.endDate)

    setCsvData(response)

    setIsFetching(false)
  }

  const downloadButton = () => {
    const filename = `Report_${dateRange.startDate}_${dateRange.endDate}.csv`

    return (
      <CSVLink data={csvData} filename={filename} style={{ marginLeft: 10 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={donwloadCSV}
          disabled={isFetching}
        >
          Download
        </Button>
      </CSVLink>
    )
  }

  return (
    <>
      <IconButton onClick={() => setShow(true)}>
        <i className="fas fa-download" />
      </IconButton>
      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={show}
        onClose={() => setShow(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <Paper>
            <div
              style={{
                padding: 20,
                minWidth: 400,
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                gap: 20,
              }}
            >
              <h4 style={{ marginBottom: 12 }}>
                Report for: {formatDateRange(dateRange.startDate, dateRange.endDate)}
              </h4>
              <div className={'flex-line'} style={{ marginBottom: 16, gap: 10 }}>
                <DateRangeSelect
                  defaultValue={dateRange}
                  handleApply={applyRange}
                  ref={dateRangeEl}
                />
                {downloadButton()}
              </div>
            </div>
          </Paper>
        </Fade>
      </Modal>
    </>
  )
}

export default ExportReportButton
