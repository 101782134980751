import styled from '@emotion/styled'

export const DatePickerButton = styled.div`
    display: flex;
  align-items: center;
  column-gap: 1em;
  flex-wrap: wrap;
`

export const PopoverBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 4px;
`
