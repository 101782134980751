import { FC } from 'react'
import { IconButton } from '@mui/material'
import { ArrowDownward } from '@mui/icons-material'
import { Table } from '@tanstack/react-table'
import styled from '@emotion/styled'

import { TradePosition, TradePositionHook } from './hooks'

import { Select, FilterSelect } from 'components/Select'
import { DebouncedInput } from 'components/DebouncedInput'

type FiltersProps = {
    table: Table<any>
    exchangePairs: any[]
    tableSize: string
    clients?: string[]
    coinPairs: string[]
    setTableSize: (size: string) => void
}

const FiltersContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 1em;
  flex-wrap: wrap;

  & > div {
    max-width: 300px;
    min-width: 150px;
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .pagesize-input {
    width: 80px;
    min-width: 80px;
  }
`

const ShortLongIconColors = {
  [TradePosition.UNSET]: 'gray',
  [TradePosition.SHORT]: 'red',
  [TradePosition.LONG]: 'green',
}

const ShortLongIcon = styled(IconButton)<{ tradePosition: TradePosition }>`
  margin-left: 6px;
  color: ${({ tradePosition }) => ShortLongIconColors[tradePosition]};
  transform: ${({ tradePosition }) => tradePosition !== TradePosition.LONG ? 'none' : 'rotate(180deg)'};
  transition: all ease .5s;
`

type ShortLongBtnProps = {
  onClick?: () => void,
  tradePosition: TradePosition,
  size?: 'large' | 'medium' | 'small'
}

export const ShortLongBtn: FC<ShortLongBtnProps> = ({ onClick, tradePosition, size = 'large' }) => (
  <ShortLongIcon
    onClick={onClick}
    tradePosition={tradePosition}
  >
    <ArrowDownward fontSize={size} />
  </ShortLongIcon>
)

export const ShortLongToggle: FC<{ tradePosition: TradePositionHook }> = ({ tradePosition }) => {
  const value = tradePosition.tradePosition

  return (
    <ShortLongBtn
      onClick={() => {
        const newPosition = value !== TradePosition.SHORT ? TradePosition.SHORT : TradePosition.LONG

        tradePosition.setTradePosition(newPosition)
      }}
      tradePosition={value}
    />
  )
}

export const Filters: FC<FiltersProps> = ({
  table,
  exchangePairs = [],
  tableSize,
  clients,
  setTableSize,
}) => {
  const column = table.getColumn('pair')
  const columnFilterValue = column?.getFilterValue()

  const exchangeColumn = table.getColumn('broker')
  const clientsColumn = clients?.length && table.getColumn('clientsCount')

  return (
    <FiltersContainer>
      <DebouncedInput
        label="Symbol"
        className="filter-input"
        value={(columnFilterValue ?? '') as string}
        onChange={value => column?.setFilterValue(value)}
        list={column?.id + 'list'}
      />
      <FilterSelect
        className="filter-input"
        column={exchangeColumn}
        values={exchangePairs}
        label="Exchange pair"
        hideColumnOnSelect
      />
      {clientsColumn && (
        <FilterSelect
          className="filter-input"
          column={clientsColumn}
          values={clients}
          label="Client"
        />
      )}
      <Select
        className="pagesize-input"
        defaultValue={tableSize}
        values={["5", "10", "15", "20", "30", "50"]}
        onChange={(e) => setTableSize(e.target.value)}
        label="Table Size"
      />
    </FiltersContainer>
  )
}