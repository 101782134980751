import { instance } from './config';

export const chartApi = {
  getChartData: (pair: any, exchange: any, from: any, to: any, position: any) => {
    const range = from && to ? `&from=${from}&to=${to}` : '';

    return instance
      .get(
        `/chart/futures-premium?symbol=${pair}${range}&exchange=${exchange}&position=${position}`
      )
      .then((res) => res.data)
      .catch((err) => console.log(err));
  },
  getFuturesOldestDate: (pair: any, exchange: any) => {
    return instance
      .get(`/chart/futures-premium/oldest?symbol=${pair}&exchange=${exchange}`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
  },
};
