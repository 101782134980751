import React from 'react'

import { formatFloat } from '../../utilities/number-helpers'

const FancyNumber: React.FC<any> = ({
  value,
  formatFn = (v: any, countChars: number | undefined) => Math.abs(formatFloat(v, countChars)),
  postfix = '',
}) => {
  if (value === '' || Number(value) == null || Number.isNaN(Number(value))) {
    return <>&nbsp;</>
  }

  const formatted = formatFn(value, 2)

  return (
    <div
      style={{
        color: value < 0 ? 'red' : 'green',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <i
        style={{ fontSize: '11px', marginRight: '3px' }}
        className={`fas fa-chevron-${value < 0 ? 'down' : 'up'}`}
      />
      {formatted + postfix}
    </div>
  )
}

export default React.memo(FancyNumber)
