// main table
export const PAIR = 'pair';
export const FUNDING = 'funding';
export const FUTURES_OPEN = 'openFp';
export const FUTURES_CLOSE = 'closeFp';
export const VOLUME = 'volume';
export const CLOSING_RATING = 'closingRate';
export const OPENING_RATING = 'openingRate';
export const CLIENTS = 'clients';
export const ONE_D = 'apr1d';
export const THREE_D = 'apr3d';
export const SEVEN_D = 'apr7d';
export const THIRTY_D = 'apr30d';

// additional table
export const CLIENT = 'name';
export const CLIENTS_COUNT = 'clientsCount';
export const POSITION = 'position';
export const INSTANCE = 'instance';
