import { useState, useCallback, Dispatch, SetStateAction } from 'react'
import { set } from 'lodash'

import { tableApi } from 'api/table-api'

export enum TradePosition {
  UNSET = '0',
  SHORT = '1',
  LONG = '2',
}

const getDataByPosition = (
  coinPair: string,
  item: AnalyticsCombinationItem,
  exchangeLeftData: AnalyticsExchangeItem,
  exchangeRightData: AnalyticsExchangeItem,
  exchangeLeft: string,
  exchangeRight: string,
  exchangePair: string,
  tradePosition: TradePosition // long by default (red)
): Analytics => {
  const side = tradePosition === TradePosition.SHORT ? 'short' : 'long'

  const leftSide = side
  const rightSide = side === 'long' ? 'short' : 'long'

  return {
    side,
    tradePosition,
    pair: coinPair,
    apr1d: item.aprs?.[side]?.d1,
    apr1dTooltip: {
      exchangeLeft,
      exchangeRight,
      updatedAt: item.aprs?.fundedAt,
      leftData: { value: exchangeLeftData?.aprs?.[leftSide].d1, updatedAt: exchangeLeftData?.aprs?.fundedAt },
      rightData: { value: exchangeRightData?.aprs?.[rightSide].d1, updatedAt: exchangeRightData?.aprs?.fundedAt },
    },
    apr3d: item.aprs?.[side].d3,
    apr3dTooltip: {
      exchangeLeft,
      exchangeRight,
      updatedAt: item.aprs?.fundedAt,
      leftData: { value: exchangeLeftData?.aprs?.[leftSide].d3, updatedAt: exchangeLeftData?.aprs?.fundedAt },
      rightData: { value: exchangeRightData?.aprs?.[rightSide].d3, updatedAt: exchangeRightData?.aprs?.fundedAt },
    },
    apr7d: item.aprs?.[side].d7,
    apr7dTooltip: {
      exchangeLeft,
      exchangeRight,
      updatedAt: item.aprs?.fundedAt,
      leftData: { value: exchangeLeftData?.aprs?.[leftSide].d7, updatedAt: exchangeLeftData?.aprs?.fundedAt },
      rightData: { value: exchangeRightData?.aprs?.[rightSide].d7, updatedAt: exchangeRightData?.aprs?.fundedAt },
    },
    apr30d: item.aprs?.[side].d30,
    apr30dTooltip: {
      exchangeLeft,
      exchangeRight,
      updatedAt: item.aprs?.fundedAt,
      leftData: { value: exchangeLeftData?.aprs?.[leftSide].d30, updatedAt: exchangeLeftData?.aprs?.fundedAt },
      rightData: { value: exchangeRightData?.aprs?.[rightSide].d30, updatedAt: exchangeRightData?.aprs?.fundedAt },
    },
    broker: exchangePair,
    closeFp: side === 'long' ? item.futuresPremium?.close : item.futuresPremium?.open,
    openFp: item.futuresPremium?.open,
    futuresPremium: item.futuresPremium,
    fpTimestamp: {
      updatedAt: item.futuresPremium?.updatedAt ? new Date(item.futuresPremium.updatedAt).toISOString() : '',
    },
    // closingRate: item.tradeRates?.closing,
    // openingRate: item.tradeRates?.opening,
    funding: item.fundingRate?.[side],
    fundingTooltip: {
      exchangeLeft,
      exchangeRight,
      updatedAt: item.fundingRate?.fundedAt,
      leftData: {
        value: exchangeLeftData?.fundingRate?.[leftSide],
        updatedAt: exchangeLeftData?.fundingRate?.fundedAt,
      },
      rightData: {
        value: exchangeRightData?.fundingRate?.[rightSide],
        updatedAt: exchangeRightData?.fundingRate?.fundedAt,
      },
    },
    volume: exchangeLeftData?.volume?.volume || exchangeRightData?.volume?.volume || undefined,
    volumeTimestamp: {
      updatedAt: exchangeLeftData?.volume?.fundedAt || exchangeRightData?.volume?.fundedAt || undefined,
    },
    // @ts-ignore
    exchangeLeft,
    exchangeRight,
    exchangeLeftData,
    exchangeRightData,
  }
}

const parseData = (data: AnalyticsData) => {
  const analytics: Record<string, Record<ExchangePairAndCoinPair, Analytics>> = {}
  const exchangePairs: string[] = []
  const coinPairs: Set<string> = new Set()

  for (const exchangePair of Object.keys(data.combinations || [])) {
    exchangePairs.push(exchangePair)

    const [exchangeLeft, exchangeRight] = exchangePair.split(':')

    const pairsItems = data.combinations[exchangePair]

    for (const coinSymbol of Object.keys(pairsItems)) {
      const [coin1, coin2, coin3] = coinSymbol.split('-')

      const coinPairLeft = !coin2 ? coin1 : `${coin1}${coin2}`
      const coinPairRight = !coin2 ? coin1 : `${coin1}${coin3}`

      coinPairs.add(coinPairLeft)
      coinPairs.add(coinPairRight)

      const exchangeLeftData = data.exchanges?.[exchangeLeft]?.[coinPairLeft] ?? null
      const exchangeRightData = data.exchanges?.[exchangeRight]?.[coinPairRight] ?? null
      const item = pairsItems[coinSymbol]

      set(analytics, ['short', `${exchangePair}_${coinSymbol}`], getDataByPosition(
        coinSymbol,
        item,
        exchangeLeftData,
        exchangeRightData,
        exchangeLeft,
        exchangeRight,
        exchangePair,
        TradePosition.SHORT
      ))

      set(analytics, ['long', `${exchangePair}_${coinSymbol}`], getDataByPosition(
        coinSymbol,
        item,
        exchangeLeftData,
        exchangeRightData,
        exchangeLeft,
        exchangeRight,
        exchangePair,
        TradePosition.LONG
      ))
    }
  }

  return { analytics, exchangePairs: exchangePairs.sort(), coinPairs: [...Array.from(coinPairs)].sort() }
}

export type TradePositionHook = { tradePosition: TradePosition, setTradePosition: Dispatch<SetStateAction<TradePosition>> }

type AnalyticsApi = [
  Record<'short' | 'long', Record<ExchangePairAndCoinPair, Analytics>>,
  TradePositionHook,
  string[],
  string[],
  () => Promise<void>,
]

export const useAnalyticsApi = (): AnalyticsApi => {
  const [tradePosition, setTradePosition] = useState(TradePosition.SHORT)
  const [analyticsData, setAnalyticsData] = useState<Record<string, Record<ExchangePairAndCoinPair, Analytics>>>({})
  const [coinPairsData, setCoinPairsData] = useState<string[]>([])
  const [exchangePairsData, setExchangePairsData] = useState<string[]>([])

  const fetch = useCallback(async () => {
    console.log('Analytics request')

    const data = await tableApi.getTablesData<AnalyticsData>()

    if (data) {
      const { analytics, coinPairs, exchangePairs } = parseData(data)

      setAnalyticsData(analytics)
      setCoinPairsData((prevCoinPairs) => {
        if (prevCoinPairs.join('') === coinPairs.join('')) {
          return prevCoinPairs
        }

        console.log('update coin pairs')

        return coinPairs
      })
      setExchangePairsData((prevExchangePairs) => {
        if (prevExchangePairs.join('') === exchangePairs.join('')) {
          return prevExchangePairs
        }

        console.log('update exchange pairs', exchangePairs)

        return exchangePairs
      })
    }
  }, [])

  return [analyticsData, { tradePosition, setTradePosition }, coinPairsData, exchangePairsData, fetch]
}

export const useClientsApi = (): [ClientPositionData[], () => Promise<void>] => {
  const [data, setData] = useState<ClientPositionData[]>([])

  // eslint-disable-next-line require-await
  const fetch = useCallback(async () => {
    console.log('Clients request')
    const response = await tableApi.getClientPositions<ClientsPositionsData>()

    // @ts-ignore
    const clients: ClientPositionData[] = []

    for (const exchangePair of Object.keys(response)) {
      for (const coinPair of Object.keys(response[exchangePair])) {
        for (const side of Object.keys(response[exchangePair][coinPair])) {
          // @ts-ignore
          const item = response[exchangePair][coinPair][side]

          clients.push({ ...item, side, coinPair, exchangePair, clientsCount: item.clients.length })
        }
      }
    }

    setData(clients)
  }, [])

  return [data, fetch]
}