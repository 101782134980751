import styled from '@emotion/styled'
import { Paper as SPaper } from '@mui/material'

export const Paper = styled(SPaper)`
    padding: 24px 36px;
    overflow: auto;
    color: rgba(0, 0, 0, 0.54) !important;
    border: 1px solid lightgrey;
`

export const Heading = styled.h4`
    margin-bottom: 36px;
`

export const Options = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`
