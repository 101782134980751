import { Button, CircularProgress } from '@mui/material'
import Snackbar from '@mui/material/Snackbar'
import React, { useState } from 'react'

import { awsLogout, getIsAuth, redirectToCognitoPage } from '../../../api/auth-api'

const AuthButton = () => {
  const [error, setError] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const isAuth = getIsAuth()

  const handleLogout = () => {
    setError('')

    const logout = async () => {
      try {
        setIsFetching(true)
        await awsLogout()

        setIsFetching(false)
      } catch (error) {
        setIsFetching(false)
        // @ts-ignore
        setError(`Error: ${error.message}`)
      }
    }

    logout()
  }

  return (
    <>
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={() => setError('')}
        message={error}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
      <Button
        onClick={isAuth ? handleLogout : () => redirectToCognitoPage()}
        disabled={isFetching}
        variant={'outlined'}
      >
        {isAuth ? 'Log out' : 'Log in'}
        {isFetching && <CircularProgress size={18} style={{ marginLeft: 8 }} />}
      </Button>
    </>
  )
}

export default AuthButton
