import React from 'react'
import { useSnackbar } from 'notistack'
import { useState, useEffect } from 'react'

import { notifierApi } from '../../api/notifier-api'

import { NotifierOption } from './components/NotifierOption'
import * as s from './styles'

const Notifier = () => {
  const [values, setValues] = useState([])

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    (async () => {
      const data = await notifierApi.getOptions()

      setValues(data)
    })()
  }, [])

  const handleChange = (key: any, event: any) => {
    const newValue = event.target.value

    setValues((prev: any) => prev.map((current: any) => (current.key === key ? { key, value: newValue } : current)))
  }

  const handleSave = async (option: any) => {
    const response = await notifierApi.saveOption(option) as any

    if (response.status === 200) {
      enqueueSnackbar('Config successfully saved', { variant: 'success' })

      return
    }
    enqueueSnackbar('Failed to save config', { variant: 'error' })
  }

  return (
    <s.Paper elevation={4}>
      <s.Heading>Notifier management</s.Heading>
      <s.Options>
        {values.map((option: any) => (
          <NotifierOption
            key={option.key}
            option={option}
            handleChange={(e: any) => handleChange(option.key, e)}
            handleSave={() => handleSave(option)}
          />
        ))}
      </s.Options>
    </s.Paper>
  )
}

export default Notifier
