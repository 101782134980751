import { Container } from '@mui/material'
import React from 'react'

import AuthButton from './AuthButton/AuthButton'
import ExportReportButton from './ExportReportButton/ExportReportButton'
import { HeaderContainer, SNavLink } from './styles'

const Header = () => (
  <Container>
    <HeaderContainer>
      <div>
        <SNavLink end to={'/'}>
          Home
        </SNavLink>
        <SNavLink end to={'/notifier'}>
          Notifier
        </SNavLink>
      </div>
      <div className="flex-line" style={{ gap: 10 }}>
        <ExportReportButton />
        <AuthButton />
      </div>
    </HeaderContainer>
  </Container>
)

export default Header
