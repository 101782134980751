import { instance } from './config';

export const tableApi = {
  getTablesData: <T = unknown>(): Promise<T> => {
    return instance
      .get('/analytics')
      .then((res) => res.data)
      .catch((err) => console.log(err));
  },
  getClientPositions: <T = unknown>(): Promise<T> => {
    return instance
      .get('/client-positions')
      .then((res) => res.data)
      .catch((err) => console.log(err));
  },

  getFuturesPremiumCalculations: (pair: any, exchange: any) => {
    return instance
      .get(`/data/getFuturesPremiumCalculation?pair=${pair}&exchangeName=${exchange}`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
  },
  getJobsResults: () => {
    return instance
      .get('/monitor/futures-premium/last-results')
      .then((res) => res.data)
      .catch((err) => console.log(err));
  },
  getHistoryByPair: (pair: any) => {
    return instance
      .get(`/monitor/futures-premium/history?pair=${pair}`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
  },
  getLongestJob: () => {
    return instance
      .get('/monitor/futures-premium/longest-job')
      .then((res) => res.data)
      .catch((err) => console.log(err));
  },
};
