import { useEffect, useState } from 'react'

import { OpenedPositionsTable } from './OpenedPositionsTable'

import Preloader from 'components/Preloader/Preloader'
import { VirtualizedTable } from 'components/VirtualizedTable'
import { TradePosition, useAnalyticsApi, useClientsApi } from 'components/VirtualizedTable/hooks'
import { useInterval } from 'hooks/use-interval'

const Tables = () => {
  const [analytics, tradePosition, coinPairs, exchangePairs, fetchAnalytics] = useAnalyticsApi()
  const [openedPositions, fetchOpenedPositions] = useClientsApi()

  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    (async () => {
      // setIsFetching(true)
      await fetchAnalytics()
      await fetchOpenedPositions()
      // setIsFetching(false)
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsFetching, fetchOpenedPositions])

  useInterval(fetchAnalytics, 10000) // tables refresh every X ms
  useInterval(fetchOpenedPositions, 55000) // clients refresh every X ms

  if (isFetching) return <Preloader />

  return (
    <div className="column">
      <VirtualizedTable
        data={Object.values(analytics[tradePosition.tradePosition === TradePosition.SHORT ? 'short' : 'long'] || {})}
        exchangePairs={exchangePairs}
        coinPairs={coinPairs}
        tradePosition={tradePosition}
      />

      <OpenedPositionsTable
        analytics={analytics}
        openedPositions={openedPositions}
        exchangePairs={exchangePairs}
        coinPairs={coinPairs}
        tradePosition={tradePosition}
      />
    </div>
  )
}

export default Tables
