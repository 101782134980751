import { FC, useEffect, useState, InputHTMLAttributes } from 'react'
import { TextField } from '@mui/material'

type DebouncedInputProps = {
    value: string | number
    label?: string
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>

export const DebouncedInput: FC<DebouncedInputProps> = ({
  value: initialValue,
  onChange,
  label,
  className,
  debounce = 500,
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <TextField
      className={className}
      label={label}
      variant="outlined"
      value={value}
      onChange={e => setValue(e.target.value)}
      size="small"
      fullWidth
    />
  )
}