import { FC } from 'react'

import { formatDateTime, formatFloat } from 'utilities/number-helpers'

export const Tooltip: FC<TooltipData> = ({ updatedAt, leftData, rightData, exchangeLeft, exchangeRight }) => {
  const leftValue = leftData && formatFloat(leftData.value)
  const rightValue = rightData && formatFloat(rightData.value)

  return (
    (
      <div>
        Updated at: {formatDateTime(updatedAt, true)}
        {leftData && (
          <div>
            <br />
            {exchangeLeft}: {leftValue ? `${leftValue}%` : ''}<br />
            Updated at: {formatDateTime(leftData.updatedAt, true)}
          </div>
        )}
        {rightData && (
          <div>
            <br />
            {exchangeRight}: {rightValue ? `${rightValue}%` : ''}<br />
            Updated at: {formatDateTime(rightData.updatedAt, true)}
          </div>
        )}
      </div>
    )
  )
}