import React from 'react'
import { Button, TextField } from '@mui/material'

export const NotifierOption: React.FC<any> = ({ option, handleChange, handleSave }) => {
  return (
    <div className="flex-line" style={{ gap: 12 }}>
      <TextField
        variant="outlined"
        label={option.label}
        value={option.value}
        onChange={handleChange}
        inputProps={{ step: 0.1 }}
      />
      <Button variant="outlined" onClick={handleSave}>
        Save
      </Button>
    </div>
  )
}

export default NotifierOption
