import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'

export const HeaderContainer = styled.div`
    display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 20px;
`

export const SNavLink = styled(NavLink)`
    display: table-cell;
    position: relative;
    color: #696969;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    display: inline-block;
    padding: 10px 14px;

    &:after {
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: 50%;
        position: absolute;
        background: #696969;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
    }

    &:hover:after {
        width: 100%;
        left: 0;
    }

    &:active:after {
        width: 100%;
        left: 0;
    }
`
