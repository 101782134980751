import { useMemo, FC, useState, useRef, useCallback } from 'react'
import {
  Typography,
  Paper,
  Popover,
  Modal,
  Backdrop
} from '@mui/material'
import {
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  useReactTable
} from '@tanstack/react-table'
import { useVirtual } from '@tanstack/react-virtual'

import { getColumns } from './columns'
import * as S from './styles'
import { Filters, ShortLongToggle } from './Filters'
import { HeaderCell } from './HeaderCell'
import { Tooltip } from './Tooltip'
import { TableRows } from './TableRows'
import { TradePositionHook } from './hooks'

import { filterByClient } from 'utilities/filtering'
import Graph from 'components/Graph/Graph'
import { useLocalStorage } from 'hooks/use-local-storage'
import { alphanumericFalsyLast } from 'utilities/sorting'

export enum TableType {
  OPEN = 'open',
  CLOSE = 'close',
}

const ROW_HEIGHT = 40

type VirtualizedTableProps = {
  heading?: string
  data: Analytics[]
  exchangePairs: string[]
  coinPairs: string[]
  tradePosition: TradePositionHook
  type?: TableType
  clients?: string[]
}

export const VirtualizedTable: FC<VirtualizedTableProps> = ({
  heading = 'Market overview:',
  data,
  exchangePairs,
  tradePosition,
  coinPairs,
  clients,
  type = TableType.CLOSE,
}) => {
  const [popoverContent, setPopoverContent] = useState<TooltipData | undefined>()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const showTooltip = useCallback((event: React.MouseEvent<HTMLButtonElement>, data: TooltipData) => {
    setAnchorEl(event.currentTarget)
    setPopoverContent(data)
  }, [])
  const handleClose = () => {
    setAnchorEl(null)
    setPopoverContent(undefined)
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [showGraph, setShowGraph] = useState(false)
  const [current, setCurrent] = useState<any>(null)
  const openGraph = (pair: any, exchange: any) => {
    setCurrent({ pair, exchange })
    setShowGraph(true)
  }
  const closeGraph = () => setShowGraph(false)

  const tableRef = useRef<HTMLDivElement>(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo<any>(() => getColumns(type, { showTooltip, openGraph }), [showTooltip])
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnVisibility, setColumnVisibility] = useState<any>({})

  const [tableSize, setTableSize] = useLocalStorage('table-size-' + type, "10")

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
    },
    sortingFns: { alphanumericFalsyLast },
    filterFns: { filterByClient },
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableFilters: true,
  })
  const { rows } = table.getRowModel()

  const rowVirtualizer = useVirtual({
    parentRef: tableRef,
    size: rows.length,
    overscan: 5,
  })
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0

  return (
    <Paper sx={{ mb: 3, width: '100%' }} elevation={3}>
      <Typography variant="h5" m={1}>
        {heading}
        {type === TableType.CLOSE && <ShortLongToggle tradePosition={tradePosition} />}
      </Typography>
      <S.Header>
        <Filters
          table={table}
          exchangePairs={exchangePairs}
          clients={clients}
          coinPairs={coinPairs}
          tableSize={tableSize}
          setTableSize={setTableSize}
        />
      </S.Header>
      <S.Container>
        <S.TableContainer ref={tableRef} tHeight={(+tableSize + (type === TableType.OPEN ? 10 : 1)) * ROW_HEIGHT}>
          <table>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => <HeaderCell key={header.id} header={header} />)}
                </tr>
              ))}
            </thead>
            <tbody>
              {paddingTop > 0 && (
                <tr>
                  <td style={{ height: `${paddingTop}px` }} />
                </tr>
              )}
              <TableRows
                virtualRows={virtualRows}
                rows={rows}
                type={type}
              />
              {paddingBottom > 0 && (
                <tr>
                  <td style={{ height: `${paddingBottom}px` }} />
                </tr>
              )}
            </tbody>
          </table>
        </S.TableContainer>
      </S.Container>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {popoverContent && <Tooltip {...popoverContent}/>}
      </Popover>
      <Modal
        open={showGraph}
        onClose={closeGraph}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <S.Box>
          <Graph current={current} />
        </S.Box>
      </Modal>
    </Paper>
  )
}
