import { FC } from 'react'
import { flexRender } from '@tanstack/react-table'
import styled from '@emotion/styled'

const SORT_ICON = {
  asc: '🔼',
  desc: '🔽',
}

const Th = styled.th<any>`
  cursor: ${({ canSort }) => canSort ? 'pointer' : 'default'};
  user-select: none;
`

const Icon = styled.span<{ disabled?: boolean }>`
    padding-left: 4px;
    padding-right: 4px;
    opacity: ${({ disabled }) => disabled ? .2 : 1};
`

const SortIcon: FC<{ isSorted?: 'asc' | 'desc' }> = ({ isSorted }) => {
  if (isSorted) {
    return <Icon>{SORT_ICON[isSorted]}</Icon>
  }

  return <Icon disabled>🔼</Icon>
}

export const HeaderCell: FC<any> = ({ header }) => {
  const isSorted = header.column.getIsSorted()
  const canSort = header.column.getCanSort()
  const headerSize = header.column.columnDef.size

  return (
    <Th
      key={header.id}
      colSpan={header.colSpan}
      canSort={canSort}
      style={headerSize ? {
        width: headerSize,
        minWidth: headerSize,
        maxWidth: headerSize,
      } : {}}
    >
      <div
        onClick={canSort && header.column.getToggleSortingHandler()}
      >
        {canSort && <SortIcon isSorted={isSorted}/>}
        {flexRender(
          header.column.columnDef.header,
          header.getContext()
        )}
      </div>
    </Th>
  )
}