import React from 'react'

import { getIsAuth, redirectToCognitoPage } from '../api/auth-api'

const withAuthRedirect = (Component: React.FC<any>) => {
  return (props: any) => {
    const isAuth = getIsAuth()

    if (isAuth) return <Component {...props} />
    redirectToCognitoPage()

    return null
  }
}

export default withAuthRedirect
