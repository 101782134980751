/* eslint-disable no-undef */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  awsCallTokenEndpoint,
  awsCallTokenRefresh,
  getAwsToken,
  isAuthExpired,
  redirectToCognitoPage
} from '../api/auth-api'

import { useQuery } from './use-query'
import { useInterval } from './use-interval'

export const useInit = () => {
  const [isAuth, setIsAuth] = useState(localStorage.getItem('authenticated') === 'true' || false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const navigate = useNavigate()
  const query = useQuery()
  const code = query?.get('code')

  const expiresIn = localStorage.getItem('expires_in') || 3600000

  useInterval(() => {
    setError(null)
    async function refreshMe() {
      const refreshToken = localStorage.getItem('refresh')

      await getAwsToken(() => awsCallTokenRefresh('refresh_token', refreshToken).catch((err) => setError(err.message)))
    }
    if (isAuth) refreshMe()
  // @ts-ignore
  }, expiresIn - 300000) // sends refresh request 5 minutes earlier before token expires

  useEffect(() => {
    setError(null)
    function authMe() {
      // requesting token after callback code is received
      setIsLoading(true)
      const codeVerifier = localStorage.getItem('codeVerifier')

      getAwsToken(() => awsCallTokenEndpoint('authorization_code', code, codeVerifier))
        .then(() => {
          setIsAuth(true)
          navigate('/')

          return true
        })
        .catch((err) => setError(`Failed token request. Error message: ${err.message}`))
      setIsLoading(false)
    }
    if (code && !isAuth) {
      authMe()
    } else if (code && isAuth) {
      window.location.replace('/')
    } else if (isAuthExpired()) {
      redirectToCognitoPage()
    }
  }, [code, navigate, isAuth])

  if (!isAuth && !code) {
    redirectToCognitoPage()
  }

  return { isLoading, error }
}
