import { Line } from 'react-chartjs-2'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'

import {
  buildChartData,
  daysAgoToDate,
  formatDateRange,
  formatDateTime
} from '../../utilities/number-helpers'
import Preloader from '../Preloader/Preloader'
import DateRangeSelect from '../DateRangeSelect/DateRangeSelect'
import { chartApi } from '../../api/chart-api'

const options: any = {
  elements: {
    point: {
      radius: 0,
    },
  },
  maintainAspectRatio: false,
  tooltips: {
    intersect: true,
  },
  scales: {
    x: [
      {
        type: 'time',
        time: {
          format: 'DD/MM/YYYY',
          tooltipFormat: 'll',
        },
      },
    ],
  },
}

const defaultOldestDate = new Date(daysAgoToDate(30))

const csvHeaders = [
  { label: 'Created At', key: 'updatedAt' },
  { label: 'Futures Premium', key: 'futuresPremium' },
  { label: 'EMA', key: 'ema' },
]

const Graph: React.FC<any> = ({ current: { pair, exchange } }) => {
  const [data, setData] = useState<any>({})
  const [csvData, setCsvData] = useState<any>([])
  const [key, setKey] = useState(Math.random())
  const [isFetching, setIsFetching] = useState(false)
  const [oldestDate, setOldestDate] = useState(defaultOldestDate)
  const [dateRange, setDateRange] = useState({
    startDate: new Date(daysAgoToDate(3)).getTime(),
    endDate: new Date().getTime(),
  })
  const dateRangeEl = useRef<any>(null)

  const graphData = useMemo<any>(
    () => ({
      open: buildChartData(data?.openData || [], dateRange.startDate, dateRange.endDate),
      close: buildChartData(data?.closeData || [], dateRange.startDate, dateRange.endDate),
    }),
    [data, dateRange]
  )

  useEffect(() => {
    let isMounted = true

    if (isMounted) setKey((prev) => prev + 1)

    return () => {
      isMounted = false
    }
  }, [pair, graphData])

  async function fetchGraphData(isMounted = true, from: any, to: any) {
    setIsFetching(true)

    const promises = [
      chartApi.getChartData(pair, exchange, from, to, 'open'),
      chartApi.getChartData(pair, exchange, from, to, 'close'),
    ]

    const [openData, closeData] = await Promise.all(promises)

    if (openData?.length && closeData?.length && isMounted) {
      const csvOpen = openData.map((el: any) => ({
        ...el,
        updatedAt: formatDateTime(el.updatedAt, true),
      }))
      const csvClose = closeData.map((el: any) => ({
        ...el,
        updatedAt: formatDateTime(el.updatedAt, true),
      }))

      setData({ openData, closeData })
      setCsvData({ open: csvOpen, close: csvClose })

      const timestamps = openData.map((el: any) => el.updatedAt)

      setDateRange({
        startDate: new Date(Math.min(...timestamps)).getTime(),
        endDate: new Date(Math.max(...timestamps)).getTime(),
      })
    }

    if (isMounted) setIsFetching(false)
    if (dateRangeEl?.current) dateRangeEl.current?.close()
  }

  useEffect(() => {
    let isMounted = true

    fetchGraphData(isMounted, dateRange.startDate, dateRange.endDate)
    chartApi.getFuturesOldestDate(pair, exchange).then((oldestDate) => {
      if (isMounted) {
        setOldestDate(oldestDate ? new Date(oldestDate) : defaultOldestDate)
      }
    })

    return () => {
      isMounted = false
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pair])

  const applyRange = (from: any, to: any) => {
    const startDate = from.getTime()
    const endDate = new Date(to).setHours(23, 59, 59, 999)

    setDateRange({ startDate, endDate })
    if (startDate < dateRange.startDate || endDate > dateRange.endDate) {
      // if chosen dateRange is out of current range, then fetch new data
      fetchGraphData(true, startDate, endDate)
    } else {
      // else, just redraw graph
      setKey((prev) => prev + 1)
    }
  }

  const downloadButton = () => {
    const filenameOpen = `${pair}_${exchange}_open.csv`
    const filenameClose = `${pair}_${exchange}_close.csv`

    return (
      <>
        <CSVLink
          data={csvData.open || []}
          headers={csvHeaders}
          filename={filenameOpen}
          style={{ marginLeft: 10 }}
        >
          Open&nbsp;
          <i className="fas fa-download" />
        </CSVLink>
        <CSVLink
          data={csvData.close || []}
          headers={csvHeaders}
          filename={filenameClose}
          style={{ marginLeft: 10 }}
        >
          Close&nbsp;
          <i className="fas fa-download" />
        </CSVLink>
      </>
    )
  }

  if (isFetching)
    return (
      <div style={{ height: '360px' }}>
        <Preloader />
      </div>
    )
  if (graphData?.length < 0) return <h3 style={{ margin: '0 auto' }}>No Data</h3>

  const { open, close } = graphData

  return (
    <div>
      <h4 style={{ marginBottom: 12 }}>
        {formatDateRange(dateRange.startDate, dateRange.endDate)}
      </h4>
      <div className={'flex-line'} style={{ marginBottom: 16 }}>
        <DateRangeSelect
          defaultValue={dateRange}
          minDate={oldestDate}
          ref={dateRangeEl}
          handleApply={applyRange}
        />
        {downloadButton()}
      </div>
      <div style={{ width: '100%' }}>
        <Line
          key={key}
          data={{
            datasets: [
              {
                label: `Open - ${pair}, ${exchange}`,
                backgroundColor: 'rgba(243,184,84,0.4)',
                borderColor: 'rgb(243,184,84)',
                borderWidth: 1.2,
                pointHitRadius: 2,
                data: open.chartData,
                order: 3,
              },
              {
                label: 'Open - EMA',
                backgroundColor: 'rgba(1,166,255,0.4)',
                pointHitRadius: 3.5,
                pointRadius: 1.5,
                borderColor: '#01a6ff',
                borderWidth: 1.75,
                data: open.eMovingAvg,
                order: 1,
              },
              {
                label: `Close - ${pair}, ${exchange}`,
                backgroundColor: 'rgba(190,0,0,0.4)',
                borderColor: 'rgb(190,0,0)',
                borderWidth: 1.2,
                pointHitRadius: 2,
                data: close.chartData,
                order: 4,
              },
              {
                label: 'Close - EMA',
                backgroundColor: 'rgba(50,190,0,0.4)',
                pointHitRadius: 3.5,
                pointRadius: 1.5,
                borderColor: 'rgb(50,190,0)',
                borderWidth: 1.75,
                data: close.eMovingAvg,
                order: 2,
              },
            ],
          }}
          options={options}
          height={420}
          redraw={false}
        />
      </div>
    </div>
  )
}

export default React.memo(Graph)
