import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import { DateRange } from 'react-date-range'

import { PopoverBody, DatePickerButton } from './styles'

const DateRangeSelect = forwardRef(({ handleApply, defaultValue, minDate }: any, ref) => {
  const [showDateRange, setShowDateRange] = useState(null)
  const [dateRange, setDateRange] = useState([
    {
      startDate: defaultValue.startDate,
      endDate: defaultValue.endDate,
      key: 'selection',
    },
  ])

  useImperativeHandle(ref, () => ({
    close: () => handleClose(),
  }))

  const handleClose = () => setShowDateRange(null)

  const handleOpen = (e: any) => setShowDateRange(e.currentTarget)

  return (
    <>
      <DatePickerButton>
        <Button onClick={handleOpen} variant={'outlined'} size={'small'}>
          Select Period
        </Button>
      </DatePickerButton>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={showDateRange}
        open={Boolean(showDateRange)}
        onClose={handleClose}
      >
        <PopoverBody>
          <DateRange
            minDate={minDate}
            maxDate={new Date()}
            showDateDisplay={false}
            rangeColors={['#F3B854', 'rgba(243,184,84,0.4)']}
            editableDateInputs={true}
            onChange={(item: any) => setDateRange([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
          />
          <Button
            onClick={() => handleApply(dateRange[0].startDate, dateRange[0].endDate)}
            variant={'outlined'}
          >
            Apply
          </Button>
        </PopoverBody>
      </Popover>
    </>
  )
})

export default DateRangeSelect
