import { FC } from 'react'
import {
  FormControl,
  MenuItem,
  InputLabel,
  Select as MuiSelect,
  NativeSelect
} from '@mui/material'
import { Column } from '@tanstack/react-table'

type SelectProps = {
  nativeOnChange?: (e: any) => void
  onChange?: (e: any) => void
  defaultValue: any
  values: string[] | number[]
  label: string
  className?: string
}

export const Select: FC<SelectProps> = ({
  nativeOnChange,
  onChange,
  defaultValue,
  values,
  label,
  className,
}) => {
  if ('ontouchstart' in window) {
    return (
      <FormControl className={className} variant="outlined" size='small' fullWidth>
        <InputLabel variant="outlined">
          {label}
        </InputLabel>
        <NativeSelect
          defaultValue={defaultValue}
          onChange={nativeOnChange || onChange}
        >
          {values.map(value => <option key={value} value={value}>{value}</option>)}
        </NativeSelect>
      </FormControl>
    )
  }

  return (
    <FormControl className={className} size='small' fullWidth>
      <InputLabel >
        {label}
      </InputLabel>
      <MuiSelect
        label={label}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {values.map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
      </MuiSelect>
    </FormControl>
  )
}

type FilterSelectProps = {
  column?: Column<any, unknown>
    values: string[]
    hideColumnOnSelect?: boolean
    label: string
    className?: string
}

export const FilterSelect: FC<FilterSelectProps> = ({
  column,
  values,
  hideColumnOnSelect,
  label,
  className,
}) => {
  const onChange = (e: any) => {
    const newVal = e.target.value

    if (newVal === '-') {
      column?.setFilterValue(undefined)
    } else {
      column?.setFilterValue(newVal)
    }

    if (hideColumnOnSelect) {
      column?.toggleVisibility(!newVal || newVal === '-')
    }
  }

  const nativeOnChange = (e: any) => {
    const newVal = e.target.value

    column?.setFilterValue(newVal)

    if (hideColumnOnSelect) {
      column?.toggleVisibility(!newVal)
    }
  }

  return (
    <Select
      label={label}
      className={className}
      onChange={onChange}
      nativeOnChange={nativeOnChange}
      defaultValue="-"
      values={['-', ...values]}
    />
  )
}