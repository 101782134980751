export function alphanumericFalsyLast(rowA: any, rowB: any, columnId: string | number): number {
  const a = rowA.getValue(columnId);
  const b = rowB.getValue(columnId);

  if (!a && !b) {
    return 0;
  }
  if (!a && b) {
    return -1;
  }
  if (a && !b) {
    return 1;
  }

  return a - b;
}
