import React from 'react'

import preloader from '../../assets/images/Preloader.gif'

const Preloader = () => {
  return (
    <img
      style={{ width: '100%', height: '100%', filter: 'grayscale(1)' }}
      src={preloader}
      alt={'Loading'}
    />
  )
}

export default Preloader
